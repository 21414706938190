var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "assessmentFormContent",
      attrs: { id: "assessmentContent" },
    },
    [
      !_vm.hideSidebarAndInfo
        ? [
            _vm.previouslyCompleted
              ? _c("div", { staticClass: "previousCompleted" }, [_vm._m(0)])
              : _c("div", { staticClass: "alertMessage" }, [
                  _c("div", { staticClass: "alert alertBlue" }, [
                    _c("p", [
                      _vm._v(
                        "\n                The answers you give to these questions will be checked out by a UK-registered prescriber to\n                help us understand and treat your condition better. Don't worry, everything is private and confidential.\n                Don't forget to tell us about any meds you're currently using.\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.memberLoginLink
                      ? _c("p", [
                          _vm._v("\n                Already a member? "),
                          _c("a", { attrs: { href: _vm.memberLoginLink } }, [
                            _vm._v("Click here"),
                          ]),
                          _vm._v(" to login.\n            "),
                        ])
                      : _vm._e(),
                  ]),
                ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          ref: "mainForm",
          staticClass: "formContentWrap",
          attrs: {
            action: "",
            enctype: "multipart/form-data",
            method: "post",
            autocomplete: "off",
            id: "mainForm",
          },
        },
        [
          _vm._l(_vm.sections, function (section, sIndex) {
            return [
              section
                ? _c("form-section", {
                    key: "section" + section.id,
                    attrs: {
                      section: section,
                      "show-section-header": false,
                      "url-add-to-basket": _vm.urlAddToBasket,
                    },
                    on: { "update-section": _vm.proceed },
                  })
                : _vm._e(),
            ]
          }),
          _vm._v(" "),
          _vm.$store.state.assessment.travel_form
            ? [
                _vm._l(_vm.multiusers, function (multiuser) {
                  return [
                    _c("input", {
                      key: "multiusername" + multiuser.id,
                      attrs: {
                        type: "hidden",
                        name: "multiusers[" + multiuser.id + "][name]",
                      },
                      domProps: { value: multiuser.name },
                    }),
                  ]
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert" }, [
      _c("p", [
        _vm._v(
          "\n                It appears you have already completed this assessment.\n                Please check your answers and confirm the answers given\n                still apply to you. If not, please update your answers.\n            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }