<template>

    <textarea
            :name="questionName"
            :id="'id_' + question.id"
            v-model="question.answer"
            :placeholder="placeholder"
            @blur="updateQuestion(question)"
            @input="updateQuestion(question, true)"
            autocomplete="off"
    >{{ question.answer }}</textarea>
    
</template>

<script>


import _ from 'lodash';
import { props, computed, methods } from "../mixins/fields";

export default {
    name: "textareaField",
    mixins: [props, computed, methods],
    props: {
        placeholder: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
        allowInputEvent: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    methods: {

        updateQuestion: _.debounce(function(question, inputEvent) {
            
            if(typeof inputEvent == 'undefined' || this.allowInputEvent) {
                question = this.validate(question);
                this.$emit('update-question', question);
            }
            
        }, 200),

        validate(question) {

            let canProceed = true;

            if (!question.answer.length && question.is_required) {
                canProceed = false
            }

            question.can_proceed = canProceed;

            return question;

        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    

</style>
