var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "productCard", class: [_vm.large ? "largeCard" : ""] },
    [
      _c("header", { staticClass: "productHeader" }, [
        _c(
          "a",
          { staticClass: "productTitleLink", attrs: { href: _vm.productUrl } },
          [
            _vm.product.name
              ? _c("h2", {
                  staticClass: "productTitle",
                  domProps: { textContent: _vm._s(_vm.product.name) },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.large
              ? _c("stock-badge", {
                  attrs: { "has-stock": _vm.inStock, small: true },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.fromPrice && !_vm.large
              ? _c(
                  "div",
                  { staticClass: "priceWrapper" },
                  [
                    _vm.product.sizes?.length
                      ? _c("span", { staticClass: "fromPrefix" }, [
                          _vm._v("From"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("multi-currency-price", {
                      attrs: { price: _vm.fromPrice },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.productDescription && _vm.large
          ? _c("div", { staticClass: "productContent" }, [
              _c("h3", { staticClass: "cardSubTitle" }, [
                _vm._v("Description"),
              ]),
              _vm._v(" "),
              _c("p", {
                domProps: { innerHTML: _vm._s(_vm.productDescription) },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.product.custom?.medication_effects && _vm.large && _vm.showEffects
          ? _c("div", { staticClass: "productContent" }, [
              _c("h3", { staticClass: "cardSubTitle" }, [_vm._v("Effects:")]),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(_vm.product.custom.medication_effects),
                },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.product.custom?.medication_type && _vm.large && _vm.showMedType
          ? _c("div", { staticClass: "productContent productContentInline" }, [
              _c("h3", { staticClass: "cardSubTitle" }, [
                _vm._v("Medication type:"),
              ]),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(_vm.product.custom.medication_type),
                },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.product.custom?.dosage && _vm.large
          ? _c("div", { staticClass: "productContent productContentInline" }, [
              _c("h3", { staticClass: "cardSubTitle" }, [_vm._v("Dosage:")]),
              _vm._v(" "),
              _c("p", {
                domProps: { innerHTML: _vm._s(_vm.product.custom.dosage) },
              }),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "productImageSection" }, [
        _vm.slideshow
          ? _c(
              "div",
              { staticClass: "productCardImage" },
              [
                _c("product-images", {
                  attrs: {
                    images: _vm.product.images,
                    "lazy-load": true,
                    size: "thumbs",
                    slideshow: true,
                    "show-arrows": true,
                    "show-thumbs": false,
                    "allow-zoom": false,
                  },
                }),
              ],
              1
            )
          : _c(
              "a",
              {
                staticClass: "productCardImage",
                attrs: { href: _vm.productUrl },
              },
              [
                _c("product-images", {
                  attrs: {
                    images: _vm.product.images,
                    "lazy-load": true,
                    size: "thumbs",
                    slideshow: false,
                    "show-arrows": false,
                    "show-thumbs": false,
                    "allow-zoom": false,
                  },
                }),
              ],
              1
            ),
      ]),
      _vm._v(" "),
      _c("footer", { staticClass: "productFooter" }, [
        _c(
          "div",
          { staticClass: "footerBottom" },
          [
            _vm.fromPrice && _vm.large
              ? _c(
                  "div",
                  { staticClass: "priceWrapper" },
                  [
                    _vm.product.sizes?.length
                      ? _c("span", { staticClass: "fromPrefix" }, [
                          _vm._v("From"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("multi-currency-price", {
                      attrs: { price: _vm.fromPrice },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("add-to-basket", {
              attrs: {
                "ajax-add-to-basket":
                  _vm.$store.state.minibasket.productCardsQuickAdd,
                "basket-button-data": _vm.basketButtonData,
                "browse-page": true,
                product: _vm.product,
                "default-size-selected-id": _vm.defaultSelectedSizeID,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }