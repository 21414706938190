var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "productSizes" },
    [
      _vm.options.length > 1
        ? [
            _vm.selectLabel
              ? _c("label", {
                  attrs: { for: "select" + _vm.id },
                  domProps: { innerHTML: _vm._s(_vm.selectLabel) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "innerSelect" },
              [
                _c("chosen", {
                  attrs: {
                    options: _vm.options,
                    name: "size",
                    label: "display_value",
                    trackBy: "id",
                    placeholder: "",
                    id: "select" + _vm.id,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.triggerChange()
                    },
                  },
                  model: {
                    value: _vm.selectedID,
                    callback: function ($$v) {
                      _vm.selectedID = $$v
                    },
                    expression: "selectedID",
                  },
                }),
              ],
              1
            ),
          ]
        : [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedID,
                  expression: "selectedID",
                },
              ],
              attrs: { type: "hidden", name: "size" },
              domProps: { value: _vm.selectedID },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.selectedID = $event.target.value
                  },
                  function ($event) {
                    return _vm.triggerChange()
                  },
                ],
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }