var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "miniBasket" }, [
    _c(
      "a",
      {
        staticClass: "button actionButton basketButton",
        attrs: { href: _vm.basketUrl, title: "View basket" },
        on: {
          click: function ($event) {
            return _vm.openMiniBasket($event)
          },
        },
      },
      [
        _c("span", { staticClass: "visuallyHidden" }, [_vm._v("View Basket")]),
        _vm._v(" "),
        _c("i", { staticClass: "svgIcon" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "29",
                height: "26",
                viewBox: "0 0 29 26",
                fill: "none",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M12.9643 2.38739L8.96072 10.0039H20.3367L16.3331 2.38739C16.0402 1.8015 16.2843 1.06914 16.8214 0.776199C17.4073 0.483255 18.1396 0.727375 18.4326 1.26444L22.9732 10.0039H27.1721C28.0021 10.0039 28.7344 10.7363 28.7344 11.5663C28.7344 12.4451 28.0021 13.1287 27.1721 13.1287L24.6332 23.2841C24.2915 24.6511 23.022 25.6276 21.6061 25.6276H7.74012C6.2754 25.6276 5.0548 24.6511 4.66421 23.2841L2.17418 13.1287C1.29535 13.1287 0.611816 12.4451 0.611816 11.5663C0.611816 10.7363 1.29535 10.0039 2.17418 10.0039H6.32423L10.8649 1.26444C11.1578 0.727375 11.8902 0.483255 12.476 0.776199C13.0131 1.06914 13.2572 1.8015 12.9643 2.38739ZM9.98603 15.4722C9.98603 15.0816 9.59543 14.691 9.20484 14.691C8.76543 14.691 8.42366 15.0816 8.42366 15.4722V20.1593C8.42366 20.5987 8.76543 20.9405 9.20484 20.9405C9.59543 20.9405 9.98603 20.5987 9.98603 20.1593V15.4722ZM14.6731 14.691C14.2337 14.691 13.8919 15.0816 13.8919 15.4722V20.1593C13.8919 20.5987 14.2337 20.9405 14.6731 20.9405C15.0637 20.9405 15.4543 20.5987 15.4543 20.1593V15.4722C15.4543 15.0816 15.0637 14.691 14.6731 14.691ZM20.9226 15.4722C20.9226 15.0816 20.532 14.691 20.1414 14.691C19.702 14.691 19.3602 15.0816 19.3602 15.4722V20.1593C19.3602 20.5987 19.702 20.9405 20.1414 20.9405C20.532 20.9405 20.9226 20.5987 20.9226 20.1593V15.4722Z",
                  fill: "currentColor",
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.itemCount > 0
          ? _c("span", {
              staticClass: "totalQty",
              domProps: { innerHTML: _vm._s(_vm.itemCount) },
            })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _vm.minibasketEnabled
      ? _c(
          "div",
          {
            staticClass: "miniBasketFlyout",
            class: [_vm.open ? "isOpen" : ""],
          },
          [
            _c("div", { staticClass: "innerMinibasket" }, [
              _c("header", { staticClass: "minibasketHeader" }, [
                _c("h3", [_vm._v("Basket")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button closeButton",
                    attrs: {
                      type: "button",
                      "aria-label": "Close mini basket",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeMiniBasket()
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      { staticClass: "svgIcon" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-xmark"] },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "minibasketContent" }, [
                _vm.basket.items.length
                  ? _c(
                      "ul",
                      { staticClass: "miniBasketItems" },
                      [
                        _vm._l(
                          _vm.basket.items,
                          function (product, productKey) {
                            return [
                              _c("li", { key: product.id }, [
                                _c("div", { staticClass: "basketCard" }, [
                                  _c("div", { staticClass: "cardImage" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "imageWrap",
                                        attrs: {
                                          href: product.item_url,
                                          title: "View " + product.item_name,
                                        },
                                      },
                                      [
                                        product.item_image.length
                                          ? _c("img", {
                                              attrs: {
                                                src: product.item_image,
                                                width: "100",
                                                height: "100",
                                                alt:
                                                  "Image of:" +
                                                  product.item_name,
                                              },
                                            })
                                          : _c("img", {
                                              attrs: {
                                                src: "/sites/default/images/placeholders/no-image-items-thumb.png",
                                                width: "100",
                                                height: "100",
                                                alt: "No image",
                                              },
                                            }),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "cardContent" }, [
                                    product.item_name
                                      ? _c("h4", {
                                          staticClass: "productTitle",
                                          domProps: {
                                            textContent: _vm._s(
                                              product.item_name
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    product.size
                                      ? _c(
                                          "div",
                                          { staticClass: "productSize" },
                                          [
                                            _c("span", {
                                              staticClass: "sizeValue",
                                              domProps: {
                                                innerHTML: _vm._s(product.size),
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "quantityWrap" }, [
                                      _c(
                                        "span",
                                        { staticClass: "productQtyLabel" },
                                        [_vm._v("Quantity: ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "productQty",
                                          domProps: {
                                            innerHTML: _vm._s(product.quantity),
                                          },
                                        },
                                        [_vm._v("1")]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "cardRight" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "button deleteButton",
                                        attrs: {
                                          type: "button",
                                          "aria-label":
                                            "Remove" +
                                            product.item_name +
                                            " from basket",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.removeItem(productKey)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "svgIcon" },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "trash"] },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass: "productPrice",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.formatPrice(
                                            product.item_price,
                                            product.sale_price
                                          )
                                        ),
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                            ]
                          }
                        ),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "noItemsBasket" }, [
                      _c("h5", [_vm._v("Your basket is currently empty")]),
                    ]),
              ]),
              _vm._v(" "),
              _vm.basket.items.length
                ? _c("footer", { staticClass: "minibasketFooter" }, [
                    _c("div", { staticClass: "basketFooterTop" }, [
                      _c("span", { staticClass: "footerLabel" }, [
                        _vm._v("Total:"),
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "footerTotal",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatPrice(_vm.basket.cost_subtotal)
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "basketFooterBottom" }, [
                      _c(
                        "a",
                        {
                          staticClass: "buttonAlt",
                          attrs: {
                            href: _vm.basketUrl,
                            title: "Continue to checkout",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Checkout\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "loadingBox",
                class: [_vm.loading ? "isLoading" : ""],
              },
              [
                _c("loading-spinner", {
                  attrs: {
                    loading: _vm.loading,
                    "loading-text": "Updating Basket",
                    "show-loading-text": true,
                  },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }