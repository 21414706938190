var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "productSidebar" },
    [
      _c("div", { staticClass: "itemTitle" }, [
        _vm.product.name
          ? _c("h1", {
              staticClass: "mainTitle",
              domProps: { innerHTML: _vm._s(_vm.product.name) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.product.custom.product_subtitle
          ? _c("h3", {
              staticClass: "itemSubtitle",
              domProps: {
                innerHTML: _vm._s(_vm.product.custom.product_subtitle),
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.showStockBadge
        ? [
            _c("stock-badge", {
              attrs: { "has-stock": _vm.hasStock, small: false },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showPrice && _vm.price
        ? _c(
            "div",
            { staticClass: "fromPrice" },
            [
              _vm.fromTextEnabled
                ? _c("span", { staticClass: "fromText" }, [_vm._v("From")])
                : _vm._e(),
              _vm._v(" "),
              _c("multi-currency-price", { attrs: { price: _vm.price } }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.inStock
        ? _c("out-of-stock-notify", {
            attrs: {
              "url-submit": _vm.outOfStockUrl,
              "button-label": "Notify me when back in stock",
              "shop-item-id": _vm.product.id,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.product.description
        ? _c("div", {
            staticClass: "itemDescription wysiwyg",
            domProps: { innerHTML: _vm._s(_vm.product.description) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("add-to-basket", {
        attrs: {
          "ajax-add-to-basket":
            _vm.$store.state.minibasket.productCardsQuickAdd,
          "basket-button-data": _vm.basketButtonData,
          product: _vm.product,
          "default-size-selected-id": _vm.defaultSelectedSizeID,
          "show-price": true,
          "price-set": _vm.price,
        },
        on: { sizeupdated: _vm.updateSize, sizechanged: _vm.sizeChanged },
      }),
      _vm._v(" "),
      _vm.product.product_type == "doctor"
        ? _c("div", { staticClass: "doctorButtonInstructions" }, [
            _c("p", [
              _vm._v(
                "\n            This link above will take you to the condition page where you can start your consultation.\n        "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.product.product_type == "doctor" || _vm.product.warnings.length
        ? _c(
            "div",
            { staticClass: "doctorWarnings" },
            [
              _vm.product.product_type == "doctor"
                ? _c("div", { staticClass: "alert" }, [
                    _c("p", [
                      _vm._v(
                        "\n                Please note: This page is only to be used as a reference of our price for this medication.\n                If you are approved you will be offered treatment for you and the prescriber to jointly consider.\n                However, the final decision will always be the prescriber's.\n            "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.product.warnings, function (warning) {
                return [
                  _c("div", {
                    class: warning.type,
                    domProps: { innerHTML: _vm._s(warning.content) },
                  }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }