<template>

    <article class="productCard" :class="[large ? 'largeCard' : '']">

        <header class="productHeader">

            <a :href="productUrl" class="productTitleLink">
                <h2
                        class="productTitle"
                        v-text="product.name"
                        v-if="product.name"
                ></h2>

                <stock-badge
                        :has-stock="inStock"
                        :small="true"
                        v-if="!large"
                ></stock-badge>

                <div class="priceWrapper" v-if="fromPrice && !large">
                    <span
                            class="fromPrefix"
                            v-if="product.sizes?.length"
                    >From</span>
                    <multi-currency-price :price="fromPrice"/>
                </div> <!-- .priceWrap -->

            </a>

            <div class="productContent" v-if="productDescription && large">
                <h3 class="cardSubTitle">Description</h3>
                <p v-html="productDescription"></p>
            </div> <!-- .productContent -->

            <div class="productContent" v-if="product.custom?.medication_effects && large && showEffects">
                <h3 class="cardSubTitle">Effects:</h3>
                <p v-html="product.custom.medication_effects"></p>
            </div> <!-- .productContent -->

            <div class="productContent productContentInline" v-if="product.custom?.medication_type && large && showMedType">
                <h3 class="cardSubTitle">Medication type:</h3>
                <p v-html="product.custom.medication_type"></p>
            </div> <!-- .productContent -->

            <div class="productContent productContentInline" v-if="product.custom?.dosage && large">
                <h3 class="cardSubTitle">Dosage:</h3>
                <p v-html="product.custom.dosage"></p>
            </div> <!-- .productContent -->

        </header>

        <section class="productImageSection">

            <div class="productCardImage" v-if="slideshow">

                <product-images
                        :images="product.images"
                        :lazy-load="true"
                        size="thumbs"
                        :slideshow="true"
                        :show-arrows="true"
                        :show-thumbs="false"
                        :allow-zoom="false"
                ></product-images>

            </div> <!-- .productCardImage -->

            <a :href="productUrl" class="productCardImage" v-else>

                <product-images
                        :images="product.images"
                        :lazy-load="true"
                        size="thumbs"
                        :slideshow="false"
                        :show-arrows="false"
                        :show-thumbs="false"
                        :allow-zoom="false"
                ></product-images>

            </a>

        </section>

        <footer class="productFooter">

            <div class="footerBottom">

                <div class="priceWrapper" v-if="fromPrice && large">
                    <span
                            class="fromPrefix"
                            v-if="product.sizes?.length"
                    >From</span>
                    <multi-currency-price :price="fromPrice"/>
                </div> <!-- .priceWrap -->

                <add-to-basket
                        :ajax-add-to-basket="$store.state.minibasket.productCardsQuickAdd"
                        :basket-button-data="basketButtonData"
                        :browse-page="true"
                        :product="product"
                        :default-size-selected-id="defaultSelectedSizeID"
                ></add-to-basket>

            </div> <!-- .footerBottom -->


        </footer>

    </article>

</template>

<script>

import _ from 'lodash';
import travelQuantityAdjust from './partials/travel_quantity_adjust';
import addToBasket from './partials/add_to_basket';
import MtcNumberInput from '../../mtc_number_input';

export default {
    name: "product-card",
    components: {
        addToBasket,
        travelQuantityAdjust,
        MtcNumberInput
    },
    props: {
        large: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        classes: {
            type: [Array],
            default (rawProps) {
                return [];
            }
        },
        //If true, price will be hidden and button will become a link
        minimal: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        product: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        fromPrice: {
            type: [Number, Boolean],
            default (rawProps) {
                return false;
            }
        },
        inStock: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        productUrl: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        productImage: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        lazyLoad: {
            type: [Boolean],
            default (rawProps) {
                return true;
            }
        },
        slideshow: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        basketButtonData: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return {
                    label: false,
                    url: false
                };
            }
        },
        category: {
            type: [Object, Array, Boolean, String],
            default (rawProps) {
                return false;
            }
        },
        showDescription: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        }
    },

    mounted() {

        const vm = this;

        if(vm.useTrustpilot) {

            vm.trustpilotReady = true;

          /*
            vm.interval = setInterval(() => {

                if (!_.isUndefined(window.Trustpilot)) {
                    clearInterval(vm.interval);
                    let elem = vm.$refs.trustbox;
                    console.log(vm.$refs.trustbox);
                    if(elem) {
                        window.Trustpilot.loadFromElement(elem);
                        vm.trustpilotReady = true;
                    }
                    vm.trustpilotReady = true;
                }

            }, 1000);
            */


        }

    },
    data() {
        return {
            showEffects: false,
            showMedType: false,
            maxDescriptionLength: 600,
            viewProductText: 'View product',
            fallbackImagePath: '/sites/default/images/placeholders/no-image-items-thumb.png',
            quantityAdjustVisible: false,
            interval: null,
            trustpilotReady: false,
            trustpilotBusinessID: false,
            trustpilotTemplateID: '',
            trustpilotLink: '',
            trustpilotStarColor: '',
            trustpilotTextColor: ''
        }
    },
    computed: {

        productDescription() {


            let text = '';

            if(this.product?.custom?.description__product_card) {
                text = this.product.custom.description__product_card;
            }else if(this.product?.description) {
                text = this.product.description;
            }


            if(text.length) {

                let maxLength = this.maxDescriptionLength;

                let append = '';

                if(text.length > maxLength) {
                    append = '...';
                }

                return text
                        .replace(/(<([^>]+)>)/gi, "")
                        .substring(0,maxLength)
                        + append;

            }

            return false;
        },

        productImageSrc() {

            if(!this.productImage) {
                return this.fallbackImagePath;
            }

            return this.productImage;
        },
        defaultSelectedSizeID() {
            if (
                typeof this.product.recommended_size_id !== 'undefined' &&
                this.product.recommended_size_id > 0
            ) {
                return this.product.recommended_size_id;
            }
            return false;
        }

    },

    methods: {


        updateQuantitySelected(qty) {


            if(!_.isUndefined(qty)) {

                qty = parseInt(qty);

                if(!isNaN(qty)) {


                    this.product.quantity = qty;
                }
            }

            this.quantityAdjustVisible = false;



        }
    }
}

</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .productCard {
        background: #fff;
        display: flex;
        flex-direction: column;
        border-radius: 3.2px;
        text-align: left;
        height: 100%;
        position: relative;
        border: 1px solid #DDD;
        max-width: 282px;


        .innerImage {
            order: 1;
            padding: 0;
        }

        .productHeader {
            order: 2;
            padding: 9px 12px 0 12px;
            text-align: inherit;

            .stockWrap {
                padding-top: 2px;
            }
        }

        .productFooter {
            order: 4;
            padding: 13px 12px 22px;
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            margin-top: auto;


            .footerBottom {
                padding: 0;
                margin-top: 0;
            }
        }

        .productTitleLink {
            color: @dark_primary;
            text-decoration: none;

            .productTitle {
                color:@dark_primary;
                transition: color 200ms linear;
            }

            .priceWrapper {

                .rems(14);
                font-style: normal;
                font-weight: 500;
                line-height: 23.958px;

                .multiPrice {
                    font-weight: 700;
                }

            }

        }

        a.productTitleLink {

            &:hover, &:active, &:focus {
                .productTitle {
                    color: @primary_color;
                    transition: color 200ms linear;
                }
            }

        }

        .productTitle {

            margin: 0;
            .rems(24);
            font-weight: 500;
            line-height: 23.594px;
            letter-spacing: -0.72px;
            color: @dark_primary;
        }

        .categoryTitle {
            .rems(14);
            line-height: 1.2em;
            font-weight: 700;
            display: none;
            margin: 0;
            padding: 12px 0 0;
        }

        .productImageSection {

            max-width: 100%;

            .productImage {

                &, &.largeImages {

                    padding: 23px 12px 0;

                    .mainImage {
                        border: none;
                        border-radius: @border_radius @border_radius 0 0;
                        overflow: hidden;
                        background: #fff;
                        padding: 0;
                    }

                }


            }

        }

        .productContent {
            padding: 15px 15px 0;

            .productTitle {
                .rems(16);
                line-height: 20px;
                font-weight: 700;
                margin: 0;
            }
        }

        .productCta {
            padding: 15px 15px 8px 15px;
            margin-top: auto;

            .button {
                margin: 15px 0 0;
                width: 100%;
            }

        }

        .basketActions {

            & > .addToBagButton {
                .rems(17);
                font-weight: 500;
                line-height: normal;
            }

            .basketButtonRow {

                .rowInput {
                    flex: 0 0 65px;

                    .mNumberInput input {
                        min-height: 41px !important;
                        height: 41px !important;
                    }
                }

                .rowButton {
                    padding-left: 15px;

                    .addToBagButton {
                        .rems(17);
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }
        }

        .productSizes {

            & > label {
                display: none;
            }

            .chosen-container {

                max-width: 100%;


            }

        }

        .button {
            &.noMargin {
                margin: 0;
            }
        }


        &.largeCard {

            border: none;
            border-radius: 0;
            padding: 0;
            background: transparent;

            .productHeader {
                padding: 0;
            }

            .productTitle {
                color: @primary_color;
                .rems(22);
                font-weight: 600;
                line-height: normal;
                margin: 0 0 3px;
            }

            .productContent {
                padding: 16px 0 0;

                .cardSubTitle {
                    .rems(17);
                    font-style: normal;
                    font-weight: 700;
                    line-height: 29px;
                    margin: 0 0 4px;
                }

                p {
                    .rems(16);
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px;
                    opacity: 0.8;
                }

                &.productContentInline {

                    display: block;

                    .cardSubTitle {
                        margin: 0;
                        display: inline;
                    }

                    p {
                        margin: 0;
                        display: inline-block;
                    }
                }
            }


            .productCardImage {
                display: block;
                max-width: 315px;
                background: #fff;
                border-radius: 23px;
                overflow: hidden;
                margin: 0 0 21px;

                .productImage {
                    padding: 0;

                    .mainImage {
                        width: 100%;
                        padding-bottom: 50%;
                        position: relative;

                        img {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%,-50%);
                            object-fit: contain;
                        }
                    }
                }
            }

            .productFooter {

                margin: auto 0 0;
                padding: 20px 0 0;

                .footerBottom {

                    .priceWrapper {

                        span {
                            .rems(26);
                            font-weight: 700;
                            line-height: 29.278px;
                            color: @dark_primary;
                        }

                    }

                    .basketActions {

                        padding: 28px 0 0;

                        .productSizes {
                            padding: 0 0 28px;
                        }

                    }

                }

            }


        }

    }


</style>
