<template>

    <li 
            class="menus" 
            :class="[(megaMenu && !isMobile) ? 'megaMenuItem' : 'menuItem', menuItem.subs.length ? 'hasChildren' : '', menuItem.css_class]" 
            v-if="menuItem.published"
            @mouseleave="onMouseLeave"
            @mouseenter="onMouseEnter"
            
    >

        <a
                :href="menuItem.url"
                :target="menuItem.target"
                class="menuItemTop"
                :class="[megaMenuActive ? 'menuTopItemActive' : '']"
                @click="toggleMegaMenu"
                :data-id="menuItem.id"
        >
            {{ menuItem.name }}
            
            <i class="menuIcon menuIconOpen" v-if="menuItem.subs?.length && (!megaMenuActive || isMobile)">

                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                            d="M1.01698 0.174446C0.784264 -0.0581487 0.407184 -0.0581487 0.174446 0.174446C-0.0581487 0.40716 -0.0581487 0.784239 0.174446 1.01698L3.57873 4.42127C3.81145 4.65386 4.18853 4.65386 4.42127 4.42127L7.82555 1.01698C8.05815 0.784263 8.05815 0.407184 7.82555 0.174446C7.59284 -0.0581487 7.21576 -0.0581487 6.98302 0.174446L3.99834 3.15913L1.01698 0.174446Z" fill="currentColor"
                    />
                </svg>

            </i>

            <i class="menuIcon menuIconClose" v-if="menuItem.subs?.length && (megaMenuActive && !isMobile)">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 319.99 320"><path d="M207.6,256,315.32,148.28a16,16,0,0,0,0-22.58l-25-25a16,16,0,0,0-22.58,0L160,208.4,52.28,100.68a16,16,0,0,0-22.58,0l-25,25a16,16,0,0,0,0,22.58L112.4,256,4.68,363.72a16,16,0,0,0,0,22.58l25,25a16,16,0,0,0,22.58,0L160,303.6,267.72,411.32a16,16,0,0,0,22.58,0l25-25a16,16,0,0,0,0-22.58Z" transform="translate(-0.01 -96)" fill="currentColor"/></svg>

            </i>
            
        </a>

    
        <div class="dropdownWrap level1" v-if="menuItems?.length" :class="[megaMenu ? 'flyoutOnly' : '']">

            <ul>
                
                <template v-for="menuListItem in menuItems">
                    
                    <li :class="[menuListItem.css_class, menuListItem.subs?.length ? 'hasChildren' : '']">
                        <a 
                                :href="menuListItem.url" 
                                :target="menuListItem.target"
                                :data-id="menuListItem.id"
                        >
                            {{ menuListItem.name }}

                            <i class="menuIcon" v-if="menuListItem.subs?.length">
                                
                                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                            d="M1.01698 0.174446C0.784264 -0.0581487 0.407184 -0.0581487 0.174446 0.174446C-0.0581487 0.40716 -0.0581487 0.784239 0.174446 1.01698L3.57873 4.42127C3.81145 4.65386 4.18853 4.65386 4.42127 4.42127L7.82555 1.01698C8.05815 0.784263 8.05815 0.407184 7.82555 0.174446C7.59284 -0.0581487 7.21576 -0.0581487 6.98302 0.174446L3.99834 3.15913L1.01698 0.174446Z" fill="currentColor"
                                    />
                                </svg>
                                
                            </i>
                            
                        </a>

                        <div class="dropdownWrap level2" v-if="menuListItem.subs?.length">

                            <ul>

                                <template v-for="menuListItem2 in menuListItem.subs">

                                    <li :class="[menuListItem2.css_class, menuListItem2.subs?.length ? 'hasChildren' : '']">
                                        
                                        <a
                                                :href="menuListItem2.url"
                                                :target="menuListItem2.target"
                                                :data-id="menuListItem2.id"
                                        >

                                            {{ menuListItem2.name }}
                                            
                                            <i class="menuIcon" v-if="menuListItem2.subs?.length">

                                                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                            d="M1.01698 0.174446C0.784264 -0.0581487 0.407184 -0.0581487 0.174446 0.174446C-0.0581487 0.40716 -0.0581487 0.784239 0.174446 1.01698L3.57873 4.42127C3.81145 4.65386 4.18853 4.65386 4.42127 4.42127L7.82555 1.01698C8.05815 0.784263 8.05815 0.407184 7.82555 0.174446C7.59284 -0.0581487 7.21576 -0.0581487 6.98302 0.174446L3.99834 3.15913L1.01698 0.174446Z" fill="currentColor"
                                                    />
                                                </svg>

                                            </i>
                                        </a>

                                        <div class="dropdownWrap level3" v-if="menuListItem2.subs?.length">

                                            <ul>

                                                <template v-for="menuListItem3 in menuListItem2.subs">

                                                    <li :class="[menuListItem3.css_class]">
                                                        <a
                                                                :href="menuListItem3.target"
                                                                :target="menuListItem3.target"
                                                                v-html="menuListItem3.name"
                                                                :data-id="menuListItem3.id"
                                                        >
                                                        </a>
                                                    </li>

                                                </template>

                                            </ul>

                                        </div> <!-- .subMenu -->
                                        
                                    </li>

                                </template>

                            </ul>

                        </div> <!-- .subMenu -->
                        
                    </li>
                    
                </template>
                
            </ul>

        </div> <!-- .subMenu -->

        <div class="megaMenuWrapper" v-if="!isMobile && megaMenu && menuItems?.length" :class="[megaMenuActive ? 'megaMenuVisible' : '']">
            
            <div class="wrapper">

                <div class="menuColumns">

                    <template v-for="(column, columnIndex) in menuColumns">
                        
                        <div class="menuColumn" :key="'menuColumn' + columnIndex" v-if="column.length && hasVisibleMenus(columnIndex)">
                            
                            <template v-for="menuItems in column">

                                <ul class="menuList" v-if="menuItems.published" v-show="menuItems.visible">

                                    <template v-for="(menuItem, index) in menuItems.menus">

                                        <template v-if="((index + 1) <= maxColumnLength) || isParentMoreLink(menuItem)">
                                            
                                            <li :key="'menuItem' + menuItem.id" v-if="menuItem.published" :class="[activeMenus[columnIndex] == menuItem.id ? 'active' : '']">
    
                                                <a 
                                                        :href="menuItem.url" 
                                                        :target="menuItem.target"
                                                        @click.prevent="showSubMenu(columnIndex, menuItem.id)"
                                                        v-if="menuItem.subs.length"
                                                        :data-id="menuItem.id"
                                                >
                                                    {{ menuItem.name }}
                                                    <i class="menuIcon" v-if="menuItem.subs?.length">
    
                                                        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                    d="M1.01698 0.174446C0.784264 -0.0581487 0.407184 -0.0581487 0.174446 0.174446C-0.0581487 0.40716 -0.0581487 0.784239 0.174446 1.01698L3.57873 4.42127C3.81145 4.65386 4.18853 4.65386 4.42127 4.42127L7.82555 1.01698C8.05815 0.784263 8.05815 0.407184 7.82555 0.174446C7.59284 -0.0581487 7.21576 -0.0581487 6.98302 0.174446L3.99834 3.15913L1.01698 0.174446Z" fill="currentColor"
                                                            />
                                                        </svg>
    
                                                    </i>
                                                </a>
    
                                                <a
                                                        :href="menuItem.url"
                                                        :target="menuItem.target"
                                                        :data-id="menuItem.id"
                                                        v-else
                                                >
                                                    {{ menuItem.name }}
                                                    <i class="menuIcon" v-if="menuItem.subs?.length">
    
                                                        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                    d="M1.01698 0.174446C0.784264 -0.0581487 0.407184 -0.0581487 0.174446 0.174446C-0.0581487 0.40716 -0.0581487 0.784239 0.174446 1.01698L3.57873 4.42127C3.81145 4.65386 4.18853 4.65386 4.42127 4.42127L7.82555 1.01698C8.05815 0.784263 8.05815 0.407184 7.82555 0.174446C7.59284 -0.0581487 7.21576 -0.0581487 6.98302 0.174446L3.99834 3.15913L1.01698 0.174446Z" fill="currentColor"
                                                            />
                                                        </svg>
    
                                                    </i>
                                                </a>
    
                                            </li>
                                            
                                        </template>
                                    </template>

                                </ul>
                                
                            </template>
                            
                            
                        </div> <!-- .menuColumn -->
                        
                    </template>
                    
                </div> <!-- .menuColumns -->
                
                
            </div> <!-- .wrapper -->
            
        </div> <!-- .megaMenu -->
       
        
    </li> <!-- .menuContainer -->

</template>

<script>

import _ from 'lodash';

export default {

    name: 'mtcMenu',
    props: {
        menuItem: {
            type: [Object, Array],
            default (rawProps) {
                return [];
            }
        },
        cssClass: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
    },
    data() {
        return {
            menuItems: [],
            megaMenu: false,
            menuColumns: [],
            megaMenuActive: false,
            activeMenus: [],
            isMobile: false,
            mobileBreakpoint: 1120,
            maxColumnLength: 10
        }
    },
    created() {
        
        const _this = this;
        
        if(this.menuItem.subs.length) {
            this.menuItems = this.menuItem.subs;
        }
        
        //console.log(this.menuItems);
        if(this.cssClass?.length) {
            const megaRegEx = new RegExp('.*?megaMenu.*?', 'i');
            this.megaMenu = megaRegEx.test(this.cssClass) > -1;
            this.menuColumns = this.buildMenuColumns();
            this.showSubMenu(0,0);
        }
        
    },
    mounted() {
        
        const _this = this;
        
        if(window.innerWidth <= this.mobileBreakpoint) {
            this.isMobile = true;
        }else{
            this.isMobile = false;
        }

        window.addEventListener('resize', () => {

            if(window.innerWidth <= _this.mobileBreakpoint) {
                _this.isMobile = true;
            }else{
                _this.isMobile = false;
            }
            
        });
    },
    methods: {
        
        buildMenuColumns() {
            
            let columns1 = [],
                columns2 = [],
                columns3 = [],
                columns4 = [];

            columns1.push({
                id: 0,
                parentID: 0,
                published: 1,
                visible: true,
                menus: this.addParentLinks(false, [...[], ...this.menuItems])
            });
            
            this.menuItems.forEach((menuItem1, index) => {

                
                if(menuItem1.subs?.length) {

                    columns2.push({
                        id: parseInt(menuItem1.id),
                        parentID: parseInt(menuItem1.parent_id),
                        published: parseInt(menuItem1.published),
                        visible: false,
                        menus: this.addParentLinks(menuItem1, menuItem1.subs)
                    });
                    
                    menuItem1.subs.forEach(menuItem2 => {
                        
                        if(menuItem2.subs?.length) {

                            columns3.push({
                                id: parseInt(menuItem2.id),
                                parentID: parseInt(menuItem2.parent_id),
                                published: parseInt(menuItem2.published),
                                visible: false,
                                menus: this.addParentLinks(menuItem2, menuItem2.subs)
                            });

                            menuItem2.subs.forEach(menuItem3 => {

                                if(menuItem3.subs?.length) {
                                    columns4.push({
                                        id: parseInt(menuItem3.id),
                                        parentID: parseInt(menuItem3.parent_id),
                                        published: parseInt(menuItem3.published),
                                        visible: false,
                                        menus: this.addParentLinks(menuItem3, menuItem3.subs)
                                    });
                                }

                            });

                        }

                    });
                    
                }
                
            });
            
            return [
                    columns1,
                    columns2,
                    columns3,
                    columns4
            ]
            
        },

        isParentMoreLink(menuItem) {
            
           return !_.isUndefined(menuItem.isParentLink);
            
        },

        addParentLinks(menuItem, subs) {
            
           
            if(menuItem) {
                
                let title = 'View ' + menuItem.name;
                
                let catIDNum = parseInt(menuItem.category_id),
                        catID = !isNaN(catIDNum) ? catIDNum : 0;
                
                let catList = 0,
                    catListID = 0;
                
                if(!_.isUndefined(menuItem.category_list)) {
                    catList = parseInt(menuItem.category_list);
                    catListID = !isNaN(catList) ? catList : 0;
                }
                
                if(catID > 0 || catListID > 0) {
                    title = 'View all';
                }
                
             
                let parentLink = {
                    
                    id: -2,
                    parent_id: 0,
                    published: 1,
                    subs: [],
                    target: '_self',
                    name: title,
                    url: menuItem.url,
                    isParentLink: true
                    
                };
                
                subs.push(parentLink);
                
            }
            
            return subs;
        },

        showSubMenu(currentColumnIndex, parentID) {
            
            this.activeMenus[currentColumnIndex] = parentID;
            
            for(const colIndex in this.activeMenus) {
                if(colIndex > currentColumnIndex) {
                    this.activeMenus[colIndex] = 0;
                }
            }
            
            const nextIndex = currentColumnIndex + 1;
            
            if(!_.isUndefined(this.menuColumns[nextIndex])) {
                
                for(const menuIndex in this.menuColumns[nextIndex]) {

                    //Clear sub menus
                    for(const allMenuIndex in this.menuColumns) {
                        if(allMenuIndex > nextIndex) {
                            for(const i in this.menuColumns[allMenuIndex]) {
                                this.menuColumns[allMenuIndex][i].visible = false;
                            }
                        }
                    }
                    
                    //Mark current as visible
                    if( this.menuColumns[nextIndex][menuIndex].id == parentID ) {
                        this.menuColumns[nextIndex][menuIndex].visible = true;
                    }else{
                        this.menuColumns[nextIndex][menuIndex].visible = false;
                    }
                    
                    
                }
                
            }

            
        },
        
        hasVisibleMenus(columnIndex) {
            
            const hasVisible = this.menuColumns[columnIndex].filter(menu => menu.visible);
            return hasVisible.length;
            
        },
        
        toggleMegaMenu(e) {
    
            if(this.megaMenu && !this.isMobile) {
                e.preventDefault();
                this.megaMenuActive = !this.megaMenuActive;
            }
            
        },

        onMouseLeave() {
            if(this.megaMenu && !this.isMobile) {
                this.megaMenuActive = false;
            }
        },

        onMouseEnter() {
            if(this.megaMenu && !this.isMobile) {
                //this.megaMenuActive = true;
            }
        }
        
            
    }

}

</script>

<style lang="less" scoped>
    @import '@site/css/variables.less';
    
    .headerInnerWrap {
        
        .mainMenu {

            li {
                &:hover {
                    & > .dropdownWrap {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: auto;
                        transition: opacity 200ms linear, visibility 200ms linear;
                    }
                }
                
                &.mobileOnly {
                    display: block !important;

                    @media @above_flyout {
                        display: none !important;
                    }
                }
            }
        }

        .menus {

            font-size: inherit;
            line-height: inherit;
            font-weight: 500;

            &.menuItem {
                position: relative;
            }

            .dropdownWrap {
                
                a {
                    font-size: inherit;
                    line-height: inherit;
                    font-weight: inherit;
                    color: @flyout_link_color;
                    white-space: nowrap;

                    &:hover, &:active, &:focus, &.active {
                        color: @highlight_color;
                        transition: color 200ms linear;
                    }

                }
            }

            @media @above_flyout {
                
                
                .menuTopItemActive {
                    color: @highlight_color;
                    
                    &:hover, &:focus, &:active {
                        color: darken(@highlight_color, 8%);
                    }
                }

                .dropdownWrap {
                    position: absolute;
                    border: 1.5px solid @secondary_color;
                    padding: 10px 0;
                    border-radius: 10px;
                    background: #fff;
                    min-width: 180px;

                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: opacity 200ms linear, visibility 200ms linear;

                    a {
                        padding: 0.7em 16px;
                        display: flex;
                        align-items: center;
                    }


                    &.level1 {
                        top: 100%;
                        left: 0;
                        margin-top: 16px;

                        &:before {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 16px;
                            position: absolute;
                            right: 0;
                            left: 0;
                            bottom: 100%;
                        }

                        &.flyoutOnly {
                            display: none;
                        }

                    }

                    &.level2 {
                        top: -1.5px;
                        left: 100%;
                    }

                    &.level1, &.level2 {
                        .menuIcon {
                            width: 8px;
                            height: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 7px;
                            transform: rotate(-90deg);

                            svg {
                                width: 100%;
                                height: auto;
                            }

                        }
                    }

                    &.level3 {
                        top: -3px;
                        left: 100%;
                    }

                    &.level2, &.level3 {

                        margin-left: 8px;

                        &:before {
                            content: "";
                            display: block;
                            width: 8px;
                            height: 100%;
                            position: absolute;
                            right: 100%;
                            top: 0;
                        }
                    }
                }

                .megaMenuWrapper {
                    display: flex;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    height: auto;
                    min-height: 400px;
                    width: 100%;
                    background: #fff;
                    padding: 40px 0;
                    margin: 20px 0 0 0;
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: opacity 300ms, visibility 300ms;

                    
                    &.megaMenuVisible {

                        opacity: 1;
                        visibility: visible;
                        pointer-events: auto;
                        transition: opacity 300ms, visibility 300ms;

                    }

                    &:before {
                        content: "";
                        display: block;
                        width: 200vw;
                        height: 20px;
                        border-top: 1px solid #DADEE7;
                        background: transparent;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        right: 0;
                        transform: translateX(-50%);
                        z-index: 3;
                    }
                    
                    &:after {
                        content: "";
                        width: 200vw;
                        height: ~"calc(100% + 4px)";
                        position: absolute;
                        top: 0;
                        bottom: -4px;
                        left: 50%;
                        transform: translateX(-50%);
                        display: block;
                        background: #fff;
                        z-index: 1;
                        border-bottom: 4px solid @secondary_color;
                    }
                    

                    .wrapper {
                        width: 100%;
                        display: flex;
                        height: 100%;
                        padding: 0 !important;
                        z-index: 2;

                        &:before, &:after {
                            display: none;
                        }
                    }

                    .menuColumns {
                        display: grid;
                        grid-template-columns: repeat(4, 25%);
                        grid-gap: 0;
                        height: 100%;
                        width: 100%;
                    }

                    .menuColumn {
                        height: 100%;
                        border-right: 1.5px solid @secondary_color;

                        &:first-child {
                            padding-left: 0;

                            .menuList {
                                a {
                                    padding-left: 0;
                                }
                            }
                        }

                        &:last-child {
                            border-right: 1.5px solid transparent;
                        }
                    }

                    .menuList {

                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        padding: 0;
                        float: none;
                        clear: both;
                        flex: 0 0 100%;
                        height: 100%;

                        &:before, &:after {
                            display: none;
                        }

                        a {
                            padding: 0.4em 48px 0.4em 25px;
                            display: flex;
                            align-items: center;
                            position: relative;
                        }

                        li {
                            list-style: none;

                            &.active {
                                a {
                                    color: @highlight_color !important;
                                    transition: color 200ms linear;
                                }
                            }
                        }

                        .menuIcon {
                            position: absolute;
                            top: 50%;
                            right: 25px;
                            transform: rotate(-90deg) translateY(-50%);
                            margin: -3px 0 0 0;
                        }

                    }
                }
                
                &.megaMenuItem {
                    & > a {
                        position: relative;
                        
                        &:after {
                            content: "";
                            display:none;
                            position: absolute;
                            top: -16px;
                            left: -24px;
                            right: -24px;
                            height: 63px;
                            width: calc(100% + 48px);
                            background: transparent;
                        }
                        
                        &.menuTopItemActive {
                            &:after {
                                display: block;
                            }
                        }
                    }
                }

            }

            @media all and (max-width: (@wrapper_width + @page_side_padding)) {
                .megaMenuWrapper {
                    .wrapper {
                        padding: 0 @page_side_padding !important;
                    }
                }
            }

            @media @flyout {

                .dropdownWrap {

                    padding: 8px 0 8px 8px;
                    
                    &.level1 {
                        &.flyoutOnly {
                            display: block;
                        }
                    }


                }

                .megaMenuWrapper {
                    display: none;
                }

            }

        }

    }
</style>
