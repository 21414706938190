var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "categoryProducts" },
    [
      _c("product-list", {
        attrs: {
          loading: _vm.loading,
          products: _vm.products,
          "parent-class": ["categoryProducts"],
          "list-class": ["categoryProductList"],
          "card-previews": true,
          minimal: false,
          "card-class": ["largeCard"],
        },
      }),
      _vm._v(" "),
      _c("loading-spinner", {
        attrs: { loading: _vm.loading, "loading-text": "Loading Products" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }