<template>

    <div class="bmiCalculator">

        <div class="bmiUnitType">
            
            <div class="row">
                
                <div class="optionButtons radioButtons">
                    
                    <div class="labelButton">
        
                        <input type="radio"
                               name="bmi[type]"
                               :id="'question_' + question.id + 'bmi_type_metric'"
                               value="Metric"
                               v-model="type"
                               :checked="type == 'Metric'"
                        />
        
                        <label 
                                :for="'question_' + question.id + 'bmi_type_metric'"
                        >
                            Metric
                        </label>
        
                    </div> <!-- .labelButton -->
        
                    <div class="labelButton">
        
                        <input type="radio"
                               name="bmi[type]"
                               :id="'question_' + question.id + 'bmi_type_imperial'"
                               value="Imperial"
                               v-model="type"
                               :checked="type == 'Imperial'"
                        />
        
                        <label 
                                :for="'question_' + question.id + 'bmi_type_imperial'"
                        >
                            Imperial
                        </label>
        
                    </div> <!-- .labelButton -->
        
                </div> <!-- .optionButtons -->
    
            </div> <!-- .row -->

        </div>
        
        
        <div class="bmiCalculatorFields">

            <div class="bmiFields bmiMetricFields" v-show="isType('Metric')">

                <fieldset class="bmiFieldset">
                    
                    <legend>Height</legend>
                    
                    <div class="row">

                        <div class="inputWrap">
    
                            <label
                                    :for="'question_' + question.id + '_bmi_height_cm'"
                            >cm</label>
    
                            <input
                                    :id="'question_' + question.id + '_bmi_height_cm'"
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="300"
                                    :name="'question[' + question.id + '][bmi][metric][height_cm]'"
                                    v-model="heightCm"
                                    @input="update('heightCm')"
                                    @change="heightCmChanged = true;"
                            >
    
                        </div> <!-- .inputWrap -->
    
                    </div> <!-- .row -->
                    
                </fieldset>

                <fieldset class="bmiFieldset">
                    
                    <legend>Weight</legend>
                    
                    <div class="row">

                        <div class="inputWrap">
    
                            <label
                                    :for="'question_' + question.id + '_bmi_height_kg'"
                            >kg</label>
    
                            <input
                                    :id="'question_' + question.id + '_bmi_height_kg'"
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="800"
                                    :name="'question[' + question.id + '][bmi][metric][weight_kg]'"
                                    v-model="weightKg"
                                    @input="update('weightKg')"
                                    @change="weightKgChanged = true;"
                            >
    
                        </div> <!-- .inputWrap -->
    
                    </div> <!-- .row -->
                    
                </fieldset>
                
            </div> <!-- .bmiMetricFields -->


            <div class="bmiFields bmiMetricFields" v-show="isType('Imperial')">

                <fieldset class="bmiFieldset">

                    <legend>Height</legend>

                    <div class="row">

                        <div class="inputWrap">

                            <label
                                    :for="'question_' + question.id + '_bmi_height_feet'"
                            >feet</label>

                            <input
                                    :id="'question_' + question.id + '_bmi_height_feet'"
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="10"
                                    :name="'question[' + question.id + '][bmi][imperial][height_feet]'"
                                    v-model="heightFeet"
                                    @input="update('heightFeet')"
                            >

                        </div> <!-- .inputWrap -->

                        <div class="inputWrap">

                            <label
                                    :for="'question_' + question.id + '_bmi_height_inches'"
                            >inches</label>

                            <input
                                    :id="'question_' + question.id + '_bmi_height_inches'"
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="12"
                                    :name="'question[' + question.id + '][bmi][imperial][height_inches]'"
                                    v-model="heightInches"
                            >

                        </div> <!-- .inputWrap -->

                    </div> <!-- .row -->

                </fieldset>

                <fieldset class="bmiFieldset">

                    <legend>Weight</legend>

                    <div class="row">

                        <div class="inputWrap">

                            <label
                                    :for="'question_' + question.id + '_bmi_height_stone'"
                            >stone</label>

                            <input
                                    :id="'question_' + question.id + '_bmi_height_stone'"
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="100"
                                    :name="'question[' + question.id + '][bmi][imperial][weight_stone]'"
                                    v-model="weightStone"
                                    @input="update('weightStone')"
                            >

                        </div> <!-- .inputWrap -->

                        <div class="inputWrap">

                            <label
                                    :for="'question_' + question.id + '_bmi_height_pounds'"
                            >pounds</label>

                            <input
                                    :id="'question_' + question.id + '_bmi_height_pounds'"
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="13"
                                    :name="'question[' + question.id + '][bmi][imperial][weight_pounds]'"
                                    v-model="weightPounds"
                            >

                        </div> <!-- .inputWrap -->

                    </div> <!-- .row -->
                    
                </fieldset>

            </div> <!-- .bmiMetricFields -->


        </div>


        <div class="bmiCalculation" v-show="bmiCalculation > 0 && !question.settings.hidden_input">

            Your BMI is: <span v-html="bmiCalculation"></span>
            
            <input 
                    type="hidden" 
                    v-model="bmiCalculation"
                    :name="questionName + '[bmi][value]'"
            >
            
        </div>
        
    </div> <!-- .bmiCalculator -->
    
</template>

<script>


import { props, computed, methods } from "../mixins/fields";

export default {
    name: 'bmiCalculator',
    mixins: [props, computed, methods],
    props: {
        setType: {
            type: [String], //'Metric' || 'Imperial'
            default (rawProps) {
                return 'Metric';
            }
        },
        setHeightCm: {
            type: [Number],
            default (rawProps) {
                return 0;
            }
        },
        setWeightKg: {
            type: [Number],
            default (rawProps) {
                return 0;
            }
        },
        setHeightFeet: {
            type: [Number],
            default (rawProps) {
                return 0;
            }
        },
        setHeightInches: {
            type: [Number],
            default (rawProps) {
                return 0;
            }
        },
        setWeightStone: {
            type: [Number],
            default (rawProps) {
                return 0;
            }
        },
        setWeightPounds: {
            type: [Number],
            default (rawProps) {
                return 0;
            }
        }
    },
    data() {
        return {
            type: 'Metric', //'Metric' || 'Imperial'
            heightCm: 0,
            weightKg: 0,
            heightFeet: 0,
            heightInches: 0,
            weightStone: 0,
            weightPounds: 0,
            currentBMIValue: 0,

            changed: {
                heightCm: false,
                weightKg: false,
                heightFeet: false,
                weightStone: false
            }
        }
    },
    created() {
        this.type = this.setType;
        this.heightCm = this.setHeightCm;
        this.weightKg = this.setWeightKg;
        this.heightFeet = this.setHeightFeet;
        this.heightInches = this.setHeightInches;
        this.weightStone = this.setWeightStone;
        this.weightPounds = this.setWeightPounds;
    },
    computed: {
        
        bmiCalculation() {
            
            let height = 0,
                weight = 0;
            
            if(this.isType('Metric')) {
                height = parseInt(this.heightCm);
                weight = parseInt(this.weightKg);
            }else{
                
                if(parseInt(this.heightFeet) > 0) {
                    let totalInches = (parseInt(this.heightFeet) * 12) + parseInt(this.heightInches);
                    height = (totalInches * 2.54);
                }
                
                if(parseInt(this.weightStone) > 0) {
                    let totalPounds = (parseInt(this.weightStone) * 14) + parseInt(this.weightPounds);
                    weight = (totalPounds * 0.453592);
                }
            }

            if(weight > 0 && height > 0) {
                return (weight / Math.pow((height / 100), 2)).toFixed(2);
            }else{
                return 0;
            }
            
        }
        
    },
    watch: {
        bmiCalculation(value, oldValue) {
           
            let val = parseFloat(value);
            
            if(!isNaN(val)) {
                this.currentBMIValue = val;
            }else{
                this.currentBMIValue = 0;
            }
        }
    },
    methods: {
        
        isType(unitType) {
            return this.type.toLowerCase() == unitType.toLowerCase();
        },
        
        update(type) {
            
            const _this = this;

            this.changed[type] = true;
            
            
            if( this.updateAllowed() ) {
                
                
                this.$nextTick(() => {

                    _this.question.answer = _this.currentBMIValue;
                    _this.updateQuestion(_this.question);

                });

            }
            
        },
        
        updateAllowed() {

       
            
            if(this.type == 'Metric') {
                
                if(this.changed.heightCm && this.changed.weightKg) {
                    return true;
                }
                
            }else if(this.type == 'Imperial') {

                if(this.changed.heightFeet && this.changed.weightStone) {
                    return true;
                }
                
            }
            
            return false;
            
        },
        
        updateQuestion: _.debounce(function(question, inputEvent) {

            if(typeof inputEvent == 'undefined' || this.allowInputEvent) {
                question = this.validate(question);
                this.$emit('update-question', question);
            }

        }, 200),

        validate(question) {

            let canProceed = true;

            if (!question.answer > 0 && question.is_required) {
                canProceed = false;
            }

            question.can_proceed = canProceed;

            return question;

        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    
    .bmiCalculator {
        
        .bmiUnitType {
            
            
        }
        
        .bmiCalculatorFields {
            
            padding: 12px 0 0;
            margin: 24px 0 0;
            border-top: 1.5px solid @border_color_dark;
            
            @media @mobile {
                padding: 20px 0 0;
            }
        }
        
        .bmiCalculation {

            padding: 20px;
            margin: 24px 0 0;
            border: 1.5px solid @border_color_dark;
            .rems(14);
            line-height: 1.2em;
            font-weight: 600;
            border-radius: 5px;
            
            span {
                font-weight: normal;
            }
            
        }
        
        .bmiFields {
            
            .row {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                gap: 0 16px;

                @media @mobile {
                    flex-wrap: wrap;
                    gap: 0;
                }
                
                &:before, &:after {
                    display: none;
                }
            }
            
            .inputWrap {
                
                position: relative;
                float: none;
                clear: both;
                padding: 0;
                flex: 1 1 100%;
                margin: 0 0 12px 0;

                &:last-child {
                    margin: 0;
                }
                
                @media @mobile {
                    flex: 0 0 100%;
                    margin-bottom: 12px;
                    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                
                input {
                    position: relative;
                    z-index: 1;
                    float: none !important;
                    clear: both !important;
                    padding-right: 64px !important;
                    -moz-appearance: textfield;

                    /* Chrome, Safari, Edge, Opera */
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                }
                
                label {
                    background: @light_color;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 4px;
                    width: 64px;
                    height: ~"calc(100% - 3px)";
                    position: absolute;
                    top: 1.5px;
                    right: 1.5px;
                    bottom: 1.5px;
                    z-index: 2;
                    border-radius: 0 5px 5px 0;
                    .rems(12);
                    line-height: normal;
                    
                }
            }
        }
        
        fieldset {
            
            legend {
                
                display: block;
                .rems(17);
                line-height: 1.2em;
                font-weight: 700;
                color: @text_color;
                margin: 0 0 8px;
                
            }
            
        }
        
    }
    

</style>
