$(document).ready(function() {
    $(function() {

        const ryftForm = $('#ryft-pay-form');
        const ryftPayButton = $('#ryftPayButton');
        const ryftLoader = $('#ryftLoader');
        const ryftPayErrors = $('#ryftPayErrors');
        let loading;
        setLoading(false);
        const clientSecret = ryftForm.data('clientSecret');
        const siteName = ryftForm.data('siteName');


        if (!ryftForm.length || typeof Ryft === 'undefined') {
            return;
        }

        let ryftPayConfig = {
            publicKey: ryftForm.data('publicKey'),
            clientSecret: clientSecret,
        };

        if (ryftForm.data('gpayEnabled')) {
            ryftPayConfig.googlePay = {
                merchantIdentifier: ryftForm.data('gpayMerchantId'),
                merchantName: siteName,
                merchantCountryCode: "GB"
            }
        }

        if (ryftForm.data('apayEnabled')) {
            ryftPayConfig.applePay = {
                merchantName: siteName,
                merchantCountryCode: "GB"
            }
        }

        Ryft.init(ryftPayConfig);

        Ryft.addEventHandler("cardValidationChanged", e => {
            ryftPayButton.attr('disabled', !e.isValid);
        });

        const ryftFormNative = document.getElementById("ryft-pay-form");
        ryftFormNative.addEventListener("submit", async (e) => {
            e.preventDefault();
            const attemptPaymentRequest = {
                clientSecret: clientSecret,
                customerEmail: ryftForm.data('customerEmail'),
            };
            setLoading(true);

            Ryft.attemptPayment(attemptPaymentRequest)
                .then((paymentSession) => {
                    if (
                        paymentSession.status === 'Approved' ||
                        paymentSession.status === 'Captured'
                    ) {
                        window.location.href = '/shop/checkout/ryftpay/complete';
                        return;
                    }
                    setLoading(false);
                    if (paymentSession.lastError) {
                        const userFacingError = Ryft.getUserFacingErrorMessage(paymentSession.lastError);
                        ryftPayErrors.html(userFacingError);
                        // Show userFacingError to customer
                        console.log('Ryft Payment Error');
                        console.log(error);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    ryftPayErrors.html(error);
                    console.log('Ryft Error');
                    console.log(error);
                });
        });

        function setLoading(isLoading) {
            loading = isLoading;
            if (loading) {
                ryftForm.css('display', 'none');
                ryftLoader.css('display', 'block');
            } else {
                ryftForm.css('display', 'inline');
                ryftLoader.css('display', 'none');
            }
        }
    });

});