<template>

    <div class="productSidebar">

        <div class="itemTitle">

            <h1
                    class="mainTitle"
                    v-if="product.name"
                    v-html="product.name"
            ></h1>

            <h3
                    class="itemSubtitle"
                    v-if="product.custom.product_subtitle"
                    v-html="product.custom.product_subtitle"
            ></h3>


        </div> <!-- .itemTitle -->
        
        <template v-if="showStockBadge">
            
            <stock-badge
                    :has-stock="hasStock"
                    :small="false"
            ></stock-badge>

        </template>

        <div class="fromPrice" v-if="showPrice && price">
            <span v-if="fromTextEnabled" class="fromText">From</span>
            <multi-currency-price :price="price"/>
        </div>
        
        <out-of-stock-notify
                v-if="!inStock"
                :url-submit="outOfStockUrl"
                button-label="Notify me when back in stock"
                :shop-item-id="product.id"
        ></out-of-stock-notify>

        <div 
                class="itemDescription wysiwyg" 
                v-if="product.description" 
                v-html="product.description"
        ></div>


        <add-to-basket
                :ajax-add-to-basket="$store.state.minibasket.productCardsQuickAdd"
                :basket-button-data="basketButtonData"
                :product="product"
                :default-size-selected-id="defaultSelectedSizeID"
                :show-price="true"
                :price-set="price"
                @sizeupdated="updateSize"
                @sizechanged="sizeChanged"
        ></add-to-basket>

        <div class="doctorButtonInstructions" v-if="product.product_type == 'doctor'">
            <p>
                This link above will take you to the condition page where you can start your consultation.
            </p>
        </div> <!-- .alert -->

        <div class="doctorWarnings" v-if="product.product_type == 'doctor' || product.warnings.length">

            <div class="alert" v-if="product.product_type == 'doctor'">
                <p>
                    Please note: This page is only to be used as a reference of our price for this medication.
                    If you are approved you will be offered treatment for you and the prescriber to jointly consider.
                    However, the final decision will always be the prescriber's.
                </p>
            </div> <!-- .alert -->

            <template v-for="warning in product.warnings">

                <div :class="warning.type" v-html="warning.content">

                </div> <!-- .alert -->

            </template>

        </div> <!-- .doctorWarnings -->

    </div> <!-- .productSidebar -->

</template>

<script>
import _ from 'lodash';
import addToBasket from "./partials/add_to_basket";
import outOfStockNotify from "../../plugins/mtc_outofstocknotify/out_of_stock_notify.vue";

export default {
    name: 'productSidebar',
    components: {
        addToBasket,
        outOfStockNotify
    },
    props: {
        product: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        outOfStockUrl: {
            type: [String],
            default (rawProps) {
                return '/no-stock-url';
            }
        },
        fromPrice: {
            type: [Number, String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        hasFullDescription: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        inStock: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        productUrl: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        productImage: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        basketButtonData: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return {
                    label: false,
                    url: false
                };
            }
        },
        category: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
    },
    data() {
        return {
            haseStock: true,
            price: 0,
            wasPrice: 0,
            showPrice: true,
            defaultSelectedSizeID: 0,
            showStockBadge: false,
            allowFromText: true,
            sizeChangeCount: -1
        }
    },
    computed: {
        fromTextEnabled() {
            return (this.product?.sizes?.length > 1 || this.product.product_type == 'doctor') && this.allowFromText;
        }
    },
    created() {

        this.setSizeFromURL();

        this.price = this.fromPrice;
        this.hasStock = this.inStock;

        if(!this.hasStock) {
            this.showPrice = false;
        }

    },
    methods: {


        setSizeFromURL() {
            const searchParams = new URLSearchParams(window.location.search);

            if(searchParams.has('size')) {
                const sizeID = Number(searchParams.get('size'));
                this.defaultSelectedSizeID = sizeID;
                this.allowFromText = false;
            }

        },

        updateSize(size) {
            
            if(!_.isUndefined(size.id)) {

                this.price = parseFloat(size.actual_price);


                this.wasPrice = 0;
                this.salePrice = parseFloat(size.sale_price);

                if((this.salePrice > 0) && (this.salePrice < size.price) ) {
                    this.wasPrice = size.price;
                }

                let stock = parseInt(size.stock);

                if(isNaN(stock)) {
                    stock = 0;
                }

                this.hasStock = stock > 0;

                if(this.hasStock) {
                    this.showPrice = true;
                }else{
                    this.showPrice = false;
                }
                

                //Hide from text after changes by the user
                if(this.sizeChangeCount > 0) {
                    this.allowFromText = false;
                }

                this.sizeChangeCount++;
             

            }


        },

        sizeChanged(size) {
            this.updateSizeURL(size);
        },

        updateSizeURL(size) {

            let sizeID = 0;

            if(!_.isUndefined(size.id)) {
                sizeID = size.id;
            }

            const url = new URL(window.location);
            const params = url.searchParams;
            const currentSize = params.get('size');

            if(!sizeID) {
                url.searchParams.set('size', false);
                window.history.pushState({}, "", url);
            }else if(currentSize != sizeID) {
                url.searchParams.set('size', sizeID);
                window.history.pushState({}, "", url);
            }

        }
    }
}
</script>

<style lang="less">

@import '@site/css/variables.less';
@import '@core/css/mixins.less';
@import '@site/../default/css/base/mixins.less';

.productSidebar {

    @sidebar_border_color: fade(#BABABA, 50%);
    
    .doctorButtonInstructions {
        padding: 24px 12px 0 12px;
        font-weight: bold;

        @media @flyout {
            text-align: center;
        }

        p {
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            color: inherit;
            text-align: inherit;
            opacity: 0.8;
        }
    }

    .itemTitle {

        margin: 0;

        .mainTitle {
            margin: 0;
        }

    }

    .itemTitle {

        margin-bottom: 0;
        text-align: inherit;

        @media @product_breakpoint {
            margin-bottom: 12px;
            text-align: center;
        }


        h1, h2  {
            .rems(44);
            line-height: 1.2em;
            font-weight: 500;
            letter-spacing: -1.76px;
            color: @primary_color;
            margin: 0;

            @media @product_breakpoint {
                .rems(28);
                line-height: 1.2em;
            }

        }

        .itemSubtitle {
            .rems(17);
            line-height: 1.2em;
            font-weight: 500;
            color: #9A9A9A;
            letter-spacing: normal;
            margin: 0;
            padding: 16px 0 0;


            @media @product_breakpoint {
                text-align: center;
            }

        }


        .productBreadcrumbs {

            padding: 0 0 40px;

            @media @mobile {
                padding: 0 0 24px;
            }

            .breadcrumbs {

                &:before, &:after {
                    display: none;
                }

                display: flex;
                width: 100%;

                & > li {

                    display: none;
                    .rems(12);
                    line-height: 19px;
                    text-transform: uppercase;
                    padding: 0 16px 0 0;
                    position: relative;

                    a {
                        font-size: inherit;
                        line-height: inherit;
                        text-transform: inherit;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    &:before {
                        content: "/";
                        position: absolute;
                        left: ~"calc(100% - 9px)";
                        top: 0;
                        bottom: 0;
                        display: inline-block;
                        color: inherit;
                    }



                    &:nth-last-child(-n+2) {
                        display: flex;
                    }

                    &:last-child {
                        padding: 0;

                        &:before {
                            display: none;
                        }

                        a {
                            color: @text_color;
                            text-decoration: none;

                            &:hover, &:active {
                                color: @text_color;
                                text-decoration: none;
                            }
                        }

                    }
                }
            }

        }

    }

    .stockWrap {

        margin: 0;
        padding: 25px 0 0;
        text-align: inherit;
        width: 100%;

        @media @product_breakpoint {
            display: flex;
            justify-content: center;
            padding: 22px 0 0;
        }


    }

    .fromPrice {

        .rems(24);
        font-weight: 500;
        line-height: 23.594px;
        letter-spacing: -0.72px;
        margin: 0;
        padding-top: 22px;
        color: @text_color;

        .multiPrice {
            color: @secondary_color;
        }

    }

    .itemDescription {

        margin: 0 0 -15px;
        padding: 19px 0 0;
        .rems(17);
        line-height: 28.9px;
        text-align: inherit;
        font-weight: 400;
        color: @text_color;

        @media @product_breakpoint {
            text-align: center;
        }

        p {
            margin-bottom: 15px;
        }
       
        p, ul, ol {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            letter-spacing: 0;

            @media @product_breakpoint {
                font-size: inherit;
                line-height: inherit;
            }
        }

        ul, ol {
            
            margin: 0 0 15px 29px !important;
            padding: 0 !important;
            
            li {
                color: inherit;
                list-style: none !important;
                position: relative;
                padding: 0 !important;
                margin-bottom: 11px;
                
                &:last-child {
                    margin-bottom: 0;
                }
                
                &:before {
                    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" fill="%231C8E3C"/></svg>');
                    width: 18px;
                    height: 24px;
                    position: absolute;
                    right: 100%;
                    top: 2px;
                    display: block;
                    margin-right: 11px;
                }

            }

        }
    }
    
    .basketActions {

        position: relative;
        padding: 0;
        margin: 22px 0 0;
        border-top: 1px solid @sidebar_border_color;
        
        @media @product_breakpoint {
            
        }

        .row {
            float: none;
            clear: both;
        }
        
        .priceWrapper {
            flex: 1 1 100%;
            display: flex;
            align-items: center;
            padding: 0 16px 0 0;
            
            .multiPrice {
                .rems(32);
                font-weight: 600;
                line-height: 29.278px;
            }
        }
        
        .row:not(.basketButtonRow) {
            border-bottom: 1px solid @sidebar_border_color;
            padding: 31px 0 31px 0;
            
            .productSizes {
                padding: 0;
                display: flex;
                gap: 15px;
                
                label {
                    display: flex;
                    align-items: center;
                    flex: 1 1 50%;
                    color: @text_color;
                    margin: 0;
                    .rems(17);
                    font-weight: 600;
                    line-height: 22.154px;
                }
                
                .innerSelect {
                    flex: 1 1 50%;
                    display: flex;
                    justify-content: flex-end;
                }
                
                .chosen-container {
                    width: 100%;
                    max-width: 247px;
                }
            }
        }

        .basketButtonRow {

            padding: 31px 0 0 0;
            
            @media @product_breakpoint {
                width: 100%;
            }
            
            &:before, &:after {
                display: none;
            }

            .rowInput {
                flex: 0 0 80px;

                .mNumberInput input {
                    min-height: 44px !important;
                    height: 44px !important;
                }
            }

            .rowButton {
                padding-left: 20px;
                flex: 1 1 auto;
                min-width: 200px;

                .addToBagButton {
                    text-align: center;
                    width: auto;
                    font-weight: 500;
                    padding: 12px 15px;
                    width: 100%;

                    @media @product_breakpoint {
                        width: 100%;
                    }
                }
            }
        }

        .coverCard {
            backdrop-filter: none !important;
        }
    }

    .doctorWarnings {
        padding-top: 24px;
    }


}

</style>
