<template>

    <div class="basketActions">

        <template v-if="directToBasket">

            <form :action="url" method="post" v-if="product.in_stock" class="basketForm" ref="productForm">


                <div class="row">

                    <product-size-select
                        :sizes="product.sizes"
                        @change="updateSize"
                        @sizechanged="sizeChanged"
                        :selected-size-id="defaultSizeSelectedId"
                        select-label="Options"
                    ></product-size-select>

                </div> <!-- .row -->

                <div class="row">

                    <div class="priceWrapper" v-if="priceSet">
                        <multi-currency-price :price="priceSet"/>
                    </div> <!-- .priceWrap -->

                </div> <!-- .row -->

                <div class="row basketButtonRow">

                    <div class="rowInput">

                        <mtc-number-input
                            :min="1"
                            :max="99"
                            :value="1"
                            :name="'quantity'"
                            @input="updateQty"
                        ></mtc-number-input>


                    </div> <!-- .rowInput -->

                    <div class="rowButton">

                        <input type="hidden" name="id" v-model="product.id">
                        <input type="hidden" name="add-bag" value="true">

                        <button
                            :type="ajaxAddToBasket ? 'button' : 'submit'"
                            class="button addToBagButton"
                            v-html="label"
                            @click.prevent="addToBasketAjax($event)"
                        ></button>

                    </div> <!-- .rowButton -->

                </div> <!-- .row basketButtonRow -->

                <div class="ajaxLoader coverCard" :class="[loading ? 'isVisible' : '']" v-if="ajaxAddToBasket">

                    <loading-spinner
                        :loading="loading"
                        :show-loading-text="false"
                    ></loading-spinner>

                </div> <!-- .ajaxLoading -->

                <div class="ajaxMessage coverCard" :class="[msg.text.length ? 'isVisible' : '']" v-if="ajaxAddToBasket">

                    <div class="ajaxMessageWrap">
                        <p :class="[msg.class]" v-html="msg.text"></p>
                    </div> <!-- .ajaxMessageWrap -->

                </div> <!-- .ajaxMessage -->


            </form>

        </template>

        <template v-else>

            <div class="buttonGrid">

                <div class="buttonItem"  v-if="!largeButton">
                    <a
                        :href="product.url"
                        v-html="'Learn more'"
                        class="button buttonOutlined"
                    ></a>
                </div> <!-- .buttonItem -->

                <div class="buttonItem" v-if="product.in_stock">

                    <a
                        :href="url"
                        v-html="label"
                        class="button  addToBagButton"
                        :class="[largeButton ? 'buttonAlt buttonLarge withArrow' : '']"
                    ></a>

                </div> <!-- .buttonItem -->

            </div> <!-- .buttonGrid -->

        </template>

        <template v-if="!product.in_stock">

            <a
                class="button addToBagButton buttonLarge noStock"
                :href="product.url"
            >
                Out of Stock
            </a>

        </template>

    </div> <!-- .basketActions -->

</template>

<script>

import _ from 'lodash';
import axios from 'axios';
import productSizeSelect from './product_size_select';
import MtcNumberInput from '../../../mtc_number_input'

export default {
    name: 'add-to-basket',
    components: {
        productSizeSelect,
        MtcNumberInput
    },
    props: {
        largeButton: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        showPrice: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        priceSet: {
            type: [Boolean, Number],
            default (rawProps) {
                return false;
            }
        },
        ajaxAddToBasket: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        basketUrl: {
            type: [String],
            default (rawProps) {
                return '/shop/addtobasket.php';
            }
        },
        product: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        browsePage: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        basketButtonData: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        defaultSizeSelectedId: {
            type: [String, Number, Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    data() {
        return {
            url: '',
            defaultURL: '/shop/addtobasket.php',
            label: 'Add to basket',
            directToBasket: true,
            quantity: 1,
            selectedSize: 0,
            loading: false,
            msg: {
                class: 'errorMsg',
                text: ''
            }
        }
    },
    created() {

        this.url = this.basketUrl;

        this.directToBasket = this.basketButtonData.add_direct_to_basket;

        if(this.directToBasket) {
            this.label = typeof this.basketButtonData.browse_label !== 'undefined' ?
                this.basketButtonData.browse_label :
                this.basketButtonData.label;
            this.url = this.basketButtonData.browse_url;
        }else{
            this.label = this.basketButtonData.label;
            this.url = this.basketButtonData.url;
        }

    },
    mounted() {

        //this.url = this.defaultURL;

    },
    methods: {
        updateQty(qty) {
            this.quantity = Number(qty);
        },
        updateSize(id) {
            this.selectedSize = id;

            this.priceUpdatedBySize(id);
        },
        sizeChanged(sizeID) {
            const size = this.priceUpdatedBySize(sizeID, true);

            if(size) {
                this.$emit('sizechanged', size);
            }
        },
        priceUpdatedBySize(id, returnSize) {

            if(returnSize === 'undefined') {
                returnSize = false;
            }

            let selectedSizeID = false;

            if(!_.isUndefined(id)) {

                const sizeID = parseInt(id);

                if(!isNaN(sizeID)) {
                    selectedSizeID = sizeID;
                }

            }

            if(!_.isUndefined(this.product?.sizes) && selectedSizeID) {

                const selectedSize = this.product.sizes.filter(size => {

                    if(size.id == selectedSizeID) {
                        return size;
                    }
                });

                if(selectedSize.length) {
                    if(returnSize) {
                        return selectedSize[0];
                    }else{
                        this.$emit('sizeupdated', selectedSize[0]);
                    }

                }
            }

            return false;

        },
        showMessage(text, success) {

            if(success) {
                this.msg.class = 'successMsg';
            }else{
                this.msg.class = 'errorMsg';
            }

            this.msg.text = text;


        },
        addToBasketAjax(event) {

            if(this.ajaxAddToBasket) {

                event.preventDefault();
                const vm = this;

                if(!vm.loading) {

                    vm.loading = true;

                    const form = this.$refs.productForm;
                    const form_data = new FormData(form);
                    form_data.set('ajax', 'true');

                    axios.post(this.url, form_data, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    })
                        .then((response) => {

                            const data = response.data;

                            vm.loading = false;

                            if (data.status == 'ok') {

                                EventBus.$emit('receiveBasketUpdate');
                                vm.showMessage('Added to basket', true);

                            }else{
                                vm.showMessage('Item could not be added to basket', false);
                            }

                        })
                        .finally(() => {
                            setTimeout(() => {
                                vm.showMessage('', false);
                            }, 4000);
                        });

                }


            }

        }
    }
}
</script>

<style lang="less">

@import '@site/css/variables.less';
@import '@core/css/mixins.less';
@import '@site/css/base/mixins.less';


.basketActions {

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .coverCard {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: rgba(255,255,255,0.8);
        backdrop-filter: blur(10px);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 35px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 200ms linear, visibility 200ms linear;

        &.isVisible {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            transition: opacity 200ms linear, visibility 200ms linear;
        }

        &.ajaxMessage {

            z-index: 3;

            p {
                margin: 0;
                font-weight: bold;

                &.successMsg {
                    color: @primary_color;
                }

                &.errorMsg {
                    color: @alert_color;
                }
            }
        }



    }


    .row {
        margin: 0;
        float: none;
        clear: both;
    }

    .addToBagButton {

        margin: 0;
        width: 100%;

        &.noStock {
            opacity: 0.5;
            background: @dark_color !important;
            border-color: @dark_color !important;
            color: #fff !important;
        }
    }

    .basketButtonRow {
        float: none;
        clear: both;
        margin: 0;
        display: flex;

        .rowInput {
            flex: 0 0 65px;
            display: flex;
            align-items: center;
        }

        .rowButton {
            flex: 1 1 100%;
            display: flex;
            align-items: center;
            padding-left: 18px;
        }
    }



}




</style>
