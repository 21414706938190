<template>
    
  <main class="categoryProducts">
      
    
      <product-list 
              :loading="loading" 
              :products="products" 
              :parent-class="['categoryProducts']" 
              :list-class="['categoryProductList']"
              :card-previews="true"
              :minimal="false"
              :card-class="['largeCard']"
      ></product-list>
    
   
      <loading-spinner
              :loading="loading"
              loading-text="Loading Products"
      ></loading-spinner>
    
      
  </main>
    
</template>

<script>

import productList from './product_list';

export default {
    name: "categoryProducts",
    components: {
        productList
    },
    props: {
        setProducts: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        }
    },
    data() {
        return {
            products: [],
            loading: false
        }
    },
    created() {
        this.products = this.setProducts;
    },
    methods: {
        
        hasProducts(products) {
            
            if(typeof products != 'undefined') {
                return products.length;
            }
            
            return false;
            
        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .categoryProducts {

        position: relative;

        .loadingSpinner {
            position: absolute;
            top: 160px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 5;
        }
        
    }
    
    .categoryProductList {

        float: none;
        clear: both;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-columns: 33.333333%;
        gap: 25px 50px;

        @media @mobile {
            grid-template-columns: repeat(2, 1fr);
            grid-auto-columns: 50%;
            gap: 10px 10px;
        }
        
        @media all and (max-width: 350px) {
            grid-template-columns: repeat(1, 1fr);
            grid-auto-columns: 100%;
        }
    }

</style>
