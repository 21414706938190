<template>

  <section class="assessmentSection" v-show="section.visible">

      <header class="sectionHeader" v-if="showSectionHeader">
          <h2
                  class="sectionTitle"
                  v-html="section.title"
          ></h2>
      </header>
      
      <template v-else-if="section.travel_section">

          <template v-for="question in section.travel_questions">


              <question-wrap
                      :question-id="question.id"
                      :question-name="question.name"
                      :travel-question="true"
                      v-if="isQuestion(question)"
                      :key="'qwrap' + question.id"
                      ref="travelQuestionWrap"
              />


          </template>

      </template>

      <template v-else-if="section.multiuser_section">

          <template v-for="(multiuser, userIndex) in assessmentMultiusers">

              <collapse-area
                      :title="section.title + ' for ' + multiuser.name"
                      :section-id="'user_group_questions_' + multiuser.id"
                      :open="userIndex < 1"
                      :has-error="!multiuser.can_proceed"
              >

                  <template v-for="question in multiuser.questions">

                      
                      <question-wrap
                              :multiuser-question="true"
                              :multiuser-id="multiuser.id"
                              :question-id="question.id"
                              :question-name="question.name"
                              :travel-question="true"
                              v-if="isQuestion(question)"
                              :key="'travel_qwrap' + question.id"
                              ref="multiUserQuestionWrap"
                              @update-errors="updateMultiuserError"
                      />

                      <warning-wrap
                              :multiuser-question="true"
                              :multiuser-id="multiuser.id"
                              :question-id="question.id"
                              v-else-if="isWarning(question)"
                              :key="'travel_wwrap' + question.id"
                      />

                      <markup-wrap
                              :multiuser-question="true"
                              :multiuser-id="multiuser.id"
                              :question-id="question.id"
                              v-else-if="isHTML(question)"
                              :key="'travel_mwrap' + question.id"
                      />
                      

                  </template>
                  
              </collapse-area>
              

          </template>

      </template>
      
      <template v-if="section.product_selection">
        
          <template v-if="$store.state.assessment.travel_form">
              
              
              <product-listings
                 :selectionEnabled="true"
                 :products="productGroups[section.multiuser_id]"
                 @option-changed="validate"
                 :multiuser-id="section.multiuser_id"
                 v-if="productGroups[section.multiuser_id]"
              />
              
          </template>

          <template v-else>
              <product-listings
                      :selectionEnabled="true"
                      :products="productGroups[0]"
                      @option-changed="validate"
                      :multiuser-id="0"
                      v-if="productGroups[0]"
                 
              />
          </template>

          
      </template>

      <template v-else-if="section.product_confirmation">

        
          <template v-for="(products, multiuserID) in selectedProductGroups">
              
              <product-listings
                      :selectionEnabled="false"
                      :products="products"
                      @option-changed="validate"
                      :multiuser-id="multiuserID"
              />
              
          </template>

          
      </template>
      
      <template v-else>
 
          <template v-for="(question, index) in section.questions">


              <question-wrap
                      :question-id="question.id"
                      v-if="isQuestion(question)"
                      :key="'qwrap' + question.id"
                      ref="questionWrap"
                      :question-number="question.number"
              />

              <warning-wrap
                      :question-id="question.id"
                      v-else-if="isWarning(question)"
                      :key="'wwrap' + question.id"
              />

              <markup-wrap
                      :question-id="question.id"
                      v-else-if="isHTML(question)"
                      :key="'mwrap' + question.id"
              />


          </template>
          
      </template>
      
      <footer class="sectionFooter">

          <button
                  type="button"
                  class="button"
                  @click.prevent="updateSection()"
                  :disabled="proceedButtonDisabled"
                  v-html="proceedButtonText"
                  v-if="!section.product_selection"
          ></button>
          

          <!-- 
          <assessment-add-to-basket
                  :products="selectedProductGroups"
                  :url-add-to-basket="urlAddToBasket"
                  v-else
          />
          -->


      </footer> <!-- .assessmentButtons -->
      
  </section>

</template>

<script>

import { mapGetters } from 'vuex';
import _ from 'lodash';

import questionWrap from './question_wrap';
import warningWrap from './warning_wrap';
import markupWrap from './markup_wrap';
import productListings from './product_listings';
import collapseArea from './collapse_area';
import assessmentAddToBasket from './assessment_add_to_basket';

export default {
    name: "formSection",
    components: {
        questionWrap,
        warningWrap,
        markupWrap,
        productListings,
        collapseArea,
        assessmentAddToBasket
    },
    props: {
        section: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        showSectionHeader: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        urlAddToBasket: {
            type: String,
            default: '/missing-url-add-to-basket',
        },
    },
    data() {
        return {
            productsSelected: false,
            selectedItems: {}
        }
    },
    computed: {
        ...mapGetters({
            productGroups: 'getAssessmentProducts',
            selectedProductGroups: 'getAssessmentSelectedProducts',
            assessmentMultiusers: 'getAssessmentMultiusers',
            proceedButtonText: 'getAssessmentSectionNextStepText'
        }),

        proceedButtonDisabled() {

            if(this.section.product_selection) {

                if(!this.productsSelected) {
                    return true;
                }

            }else if(!_.isUndefined(this.section.loading)) {

                if(this.section.loading) {
                    return true;
                }

            }

            return false;

        },
        
        multiuserID() {
            
            if(!_.isUndefined(this.section.multiuser_id)) {
                return this.section.multiuser_id;
            }
            
            return 0;
        }
    },
    methods: {

        isQuestion(question) {
            return question.is_a_question;
        },

        isWarning(question) {
            return ['error', 'alert', 'success'].indexOf(question.question_type) > -1;
        },

        isHTML(question) {
            return question.question_type == 'html';
        },
        
        updateSection: _.debounce(function() {

            this.validate();
            this.$emit('update-section', this.section);

        }, 200),

        validate() {

            let canProceed = true;

            let questionWrap = this.$refs.questionWrap;

            if(this.$store.state.assessment.travel_form) {
                questionWrap = this.$refs.travelQuestionWrap;
            }
            
            if(this.section.multiuser_section) {
                questionWrap = this.$refs.multiUserQuestionWrap;
            }
            
            if(this.section.product_selection) {
                if(!this.hasSelectedItems()) {
                    this.productsSelected = false;
                    canProceed = false;
                }else{
                    this.productsSelected = true;
                }
            }else {
                
                //Force validation on all questions
                if ( ! _.isUndefined(questionWrap) && questionWrap.length) {

                    questionWrap.forEach(questionWrap => {
                        questionWrap.externalValidation();
                    });

                    //Check that individual questions can proceed
                    if (!this.section.multiuser_section) {

                        let invalidQuestions = this.section.questions.filter(question => !question.can_proceed ? question : false);

                        if (this.$store.state.assessment.travel_form) {
                            invalidQuestions = this.section.travel_questions.filter(question => !question.can_proceed ? question : false);
                        }

                        if (invalidQuestions.length) {
                            canProceed = false;

                            let visibleInvalidQuestions = invalidQuestions.filter(question => question.visible);

                            if (visibleInvalidQuestions.length) {

                                let firstInvalid = visibleInvalidQuestions[0];
                                let targetID = 'question_wrap_' + firstInvalid.id;

                                document.getElementById(targetID).scrollIntoView({
                                    behavior: 'smooth'
                                });

                            }
                        }

                        //Check that multi user sections can proceed
                    } else {

                        let targetID = false;

                        for (const prop in this.$store.state.assessment.multiusers) {

                            if (!this.$store.state.assessment.multiusers[prop].can_proceed) {
                                targetID = 'user_group_questions_' + this.$store.state.assessment.multiusers[prop].id
                                canProceed = false;
                            }

                        }

                        if (targetID) {

                            document.getElementById(targetID).scrollIntoView({
                                behavior: 'smooth'
                            });

                        }

                    }

                }
                
            }
            

            this.section.can_proceed = canProceed;

            return this.section;

        },

        hasSelectedItems() {

            let selected = {};
            
            if(!_.isUndefined(this.$store.state.assessment.selected_products[this.multiuserID])) {
                selected = this.$store.state.assessment.selected_products[this.multiuserID];
            }

            let selectedItems = 0;
            
            for(const prop in selected) {
            
                if(Object.keys(selected[prop]).length) {
                    selectedItems++;
                }
                
            }
           
      
            return selectedItems > 0;
            
        },

        updateMultiuserError(data) {
            
            this.$store.dispatch('setAssessmentMultiuserErrors', data);
            
        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .assessmentSection {

        width: 100%;
    

        @media all and (max-width: 1020px) {
            max-width: 100%;
        }

        .sectionTitle {
            .rems(20);
            line-height: 1.2em;
            font-weight: 700;
            margin: 0 0 24px;
        }

        .sectionFooter {
            padding: 27px 0 0;
        }

        .button {
            margin: 0;
            border-radius: 4px;
            .rems(17);
            font-weight: 600;
            line-height: 29px;
            padding: 0.677em 1.1em;
            min-width: 216px;
            
            @media all and (max-width: 800px) {
                width: 100%;
                max-width: 100%;
            }
        }

    }



</style>
